<template>
  <section class="container my-16" style="">
    <div class="row">
      <div class="col-xs-12">
        <h2 class="st-title black text-center font-weight-black">
          Página no encontrada
        </h2>
      </div>
      <div class="col-xs-12 pt-4">
        <div class="text-center">
          <img
            class="mt-6"
            alt="undraw_my_files_swob.svg"
            src="../assets/ilustracions/undraw_page_not_found_su7k.svg"
            height="200"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>